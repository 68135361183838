<template>
    <div class="item_wrap ">
        <div class="item_category_title">Art</div>
        <div class="item_category_wrap">
            <button class="item_category" :class="{'active':categoryType===''}" @click="changeCategory('')">ALL</button>
            <button class="item_category" :class="{'active':categoryType===data.node_id}"
                    @click="changeCategory(data.node_id)" v-for="data in itemCategoryList"
                    :key="`categort${data.node_id}`">
                {{ data.NAME }}
            </button>
        </div>
        <ul class="item_card_wrap">
            <li class="list_item">
                <div class="thumbnail">
                    <div class="card_category cc_category">Art</div>
                </div>
                <div class="card_detail">
                    <div class="profile_group">
                        <img class="card_profile" src="@/assets/image/main/profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/temp_profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/header_profile.png" alt="">
                    </div>
                    <div class="icon_group">
                        <div class="icon_wrap pr-10">
                            <img class="icon heart" src="@/assets/image/main/heart.png" alt="">
                            <div class="icon_count">251</div>
                        </div>
                        <div class="icon_wrap">
                            <img class="icon eye" src="@/assets/image/main/eye.png" alt="">
                            <div class="icon_count">1,251</div>
                        </div>
                    </div>
                    <div class="card_title">다방면의 작업노하우로 일러스트 삽화 인포그래픽 제작해 드립니다.</div>
                    <div class="premium">Premium</div>
                    <div class="item_status time">14:35:53</div>
                    <div class="item_price">
                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">
                        <div class="price">1,000,000 Mg</div>
                    </div>
                </div>
            </li>
            <li class="list_item">
                <div class="thumbnail">
                    <div class="card_category cc_category">Art</div>
                </div>
                <div class="card_detail">
                    <div class="profile_group">
                        <img class="card_profile" src="@/assets/image/main/profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/temp_profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/header_profile.png" alt="">
                    </div>
                    <div class="icon_group">
                        <div class="icon_wrap pr-10">
                            <img class="icon heart" src="@/assets/image/main/heart.png" alt="">
                            <div class="icon_count">251</div>
                        </div>
                        <div class="icon_wrap">
                            <img class="icon eye" src="@/assets/image/main/eye.png" alt="">
                            <div class="icon_count">1,251</div>
                        </div>
                    </div>
                    <div class="card_title">일러스트 삽화 제작해 드립니다.</div>
                    <div class="item_status">라이선스판매</div>
                    <div class="item_price">
                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">
                        <div class="price">1,000,000 Mg</div>
                    </div>
                </div>
            </li>
            <li class="list_item">
                <div class="thumbnail">
                    <div class="card_category cc_category">Art</div>
                </div>
                <div class="card_detail">
                    <div class="profile_group">
                        <img class="card_profile" src="@/assets/image/main/profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/temp_profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/header_profile.png" alt="">
                    </div>
                    <div class="icon_group">
                        <div class="icon_wrap pr-10">
                            <img class="icon heart" src="@/assets/image/main/heart.png" alt="">
                            <div class="icon_count">251</div>
                        </div>
                        <div class="icon_wrap">
                            <img class="icon eye" src="@/assets/image/main/eye.png" alt="">
                            <div class="icon_count">1,251</div>
                        </div>
                    </div>
                    <div class="card_title">일러스트 삽화 제작해 드립니다.</div>
                    <div class="item_status">지정가판매</div>
                    <div class="item_price">
                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">
                        <div class="price">1,000,000 Mg</div>
                    </div>
                </div>
            </li>
            <li class="list_item">
                <div class="thumbnail">
                    <div class="card_category cc_category">Art</div>
                </div>
                <div class="card_detail">
                    <div class="profile_group">
                        <img class="card_profile" src="@/assets/image/main/profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/temp_profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/header_profile.png" alt="">
                    </div>
                    <div class="icon_group">
                        <div class="icon_wrap pr-10">
                            <img class="icon heart" src="@/assets/image/main/heart.png" alt="">
                            <div class="icon_count">251</div>
                        </div>
                        <div class="icon_wrap">
                            <img class="icon eye" src="@/assets/image/main/eye.png" alt="">
                            <div class="icon_count">1,251</div>
                        </div>
                    </div>
                    <div class="card_title">일러스트 삽화 제작해 드립니다.</div>
                    <div class="item_status time">14:35:53</div>
                    <div class="item_price">
                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">
                        <div class="price">1,000,000 Mg</div>
                    </div>
                </div>
            </li>
            <li class="list_item">
                <div class="thumbnail">
                    <div class="card_category cc_category">Art</div>
                </div>
                <div class="card_detail">
                    <div class="profile_group">
                        <img class="card_profile" src="@/assets/image/main/profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/temp_profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/header_profile.png" alt="">
                    </div>
                    <div class="icon_group">
                        <div class="icon_wrap pr-10">
                            <img class="icon heart" src="@/assets/image/main/heart.png" alt="">
                            <div class="icon_count">251</div>
                        </div>
                        <div class="icon_wrap">
                            <img class="icon eye" src="@/assets/image/main/eye.png" alt="">
                            <div class="icon_count">1,251</div>
                        </div>
                    </div>
                    <div class="card_title">다방면의 작업노하우로 일러스트 삽화 인포그래픽 제작해 드립니다.</div>
                    <div class="premium">Premium</div>
                    <div class="item_status date">D-14</div>
                    <div class="item_price">
                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">
                        <div class="price">1,000,000 Mg</div>
                    </div>
                </div>
            </li>
            <li class="list_item">
                <div class="thumbnail">
                    <div class="card_category cc_category">Art</div>
                </div>
                <div class="card_detail">
                    <div class="profile_group">
                        <img class="card_profile" src="@/assets/image/main/profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/temp_profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/header_profile.png" alt="">
                    </div>
                    <div class="icon_group">
                        <div class="icon_wrap pr-10">
                            <img class="icon heart" src="@/assets/image/main/heart.png" alt="">
                            <div class="icon_count">251</div>
                        </div>
                        <div class="icon_wrap">
                            <img class="icon eye" src="@/assets/image/main/eye.png" alt="">
                            <div class="icon_count">1,251</div>
                        </div>
                    </div>
                    <div class="card_title">일러스트 삽화 제작해 드립니다.</div>
                    <div class="item_status">판매안함</div>
                    <div class="item_price">
                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">
                        <div class="price">1,000,000 Mg</div>
                    </div>
                </div>
            </li>
            <li class="list_item">
                <div class="thumbnail">
                    <div class="card_category cc_category">Art</div>
                </div>
                <div class="card_detail">
                    <div class="profile_group">
                        <img class="card_profile" src="@/assets/image/main/profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/temp_profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/header_profile.png" alt="">
                    </div>
                    <div class="icon_group">
                        <div class="icon_wrap pr-10">
                            <img class="icon heart" src="@/assets/image/main/heart.png" alt="">
                            <div class="icon_count">251</div>
                        </div>
                        <div class="icon_wrap">
                            <img class="icon eye" src="@/assets/image/main/eye.png" alt="">
                            <div class="icon_count">1,251</div>
                        </div>
                    </div>
                    <div class="card_title">다방면의 작업노하우로 일러스트 삽화 인포그래픽 제작해 드립니다.</div>
                    <div class="item_status"></div>
                    <div class="item_price">
                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">
                        <div class="price">1,000,000 Mg</div>
                    </div>
                </div>
            </li>
            <li class="list_item">
                <div class="thumbnail">
                    <div class="card_category cc_category">Art</div>
                </div>
                <div class="card_detail">
                    <div class="profile_group">
                        <img class="card_profile" src="@/assets/image/main/profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/temp_profile.png" alt="">
                        <img class="card_profile" src="@/assets/image/main/header_profile.png" alt="">
                    </div>
                    <div class="icon_group">
                        <div class="icon_wrap pr-10">
                            <img class="icon heart" src="@/assets/image/main/heart.png" alt="">
                            <div class="icon_count">251</div>
                        </div>
                        <div class="icon_wrap">
                            <img class="icon eye" src="@/assets/image/main/eye.png" alt="">
                            <div class="icon_count">1,251</div>
                        </div>
                    </div>
                    <div class="card_title">일러스트 삽화 제작해 드립니다.</div>
                    <div class="item_status">라이선스판매</div>
                    <div class="item_price">
                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">
                        <div class="price">1,000,000 Mg</div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "ItemListHomeArtLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            categoryType: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        changeCategory(data) {

        }
    },
    watch: {},
}
</script>

<style scoped>

</style>